window.addEventListener('DOMContentLoaded', () => {
// Navtoggler Logic
  document.querySelector('.sidebar__buttonbar-navtoggler').addEventListener('click', () => {
    document.body.classList.toggle('body--activenav');
  })

// Dropdown Logic Contentarea Headernav
  for (let parentNavItem of document.querySelectorAll('.contentarea__headerbar_nav ul > li.parent')) {
    parentNavItem.querySelector('a, span').addEventListener('click', () => {
      parentNavItem.classList.toggle('open');
    })
  }

// Scroll-Logic for Dialog Page
  if (document.querySelector('.dialog-page__container')) {
    document.querySelector('.dialog-page__container').scroll(0, 1000000000);
  }

// Sidebaroggler Loci
  if (document.querySelector('.sidebarview__sidebar-toggler')) {
    document.querySelector('.sidebarview__sidebar-toggler').addEventListener('click', () => {
      document.querySelector('.sidebarview__sidebar').classList.toggle('sidebarview__sidebar--active')
    })
  }

  // Prune chat logic if user state changes
  if (window.localStorage.getItem('user_id') != window.Joomla.getOptions('tpl_eappportal').userId) {
    window.localStorage.clear()
    window.sessionStorage.clear()

    window.localStorage.setItem('user_id', window.Joomla.getOptions('tpl_eappportal').userId)
  }
})